import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import {useNavigate} from 'react-router-dom';
import {
    Box,
    Divider,
    Hidden,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {useAuthsState} from "../auths/authsContext";
import {MENU_CLOSED, useAppDispatch, useAppState, useAppTranslation} from "../app/appContext";
import {useDispatch, useSelector} from "react-redux";
import {Apps, Interests, Museum} from "@mui/icons-material";
import {
    filterInboxByApp, filterInboxByOwner,
    filterInboxByType,
    unfilterInboxByApp,
    unfilterInboxByOwner,
    unfilterInboxByType
} from "../messages/filterAction";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    drawer: {
        zIndex: theme.zIndex.appBar - 1
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    drawerTitle: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export const Menu = () => {
    const {menuOpen} = useAppState()
    const appDispatch = useAppDispatch();
    const navigate = useNavigate()
    const {userIsAuthenticated} = useAuthsState();
    const classes = useStyles({menuOpen: menuOpen});


    const inbox = useSelector(state => state.inbox);
    const inboxFilter = useSelector(state => state.inboxFilter);
    const dispatch = useDispatch();
    const t = useAppTranslation()


    const filterMessages = (messages, inboxFilter) => {
        let filtered = {
            all: messages,
            byAppAndType: messages,
            byAppAndOwner: messages,
            byTypeAndOwner: messages,
            byAll: messages
        };

        if(inboxFilter.appName) {
            filtered.byAppAndType = filtered.byAppAndType.filter(message => message.appName === inboxFilter.appName);
            filtered.byAppAndOwner = filtered.byAppAndOwner.filter(message => message.appName === inboxFilter.appName);
            filtered.byAll = filtered.byAll.filter(message => message.appName === inboxFilter.appName);
        }

        if(inboxFilter.inboxType) {
            filtered.byAppAndType = filtered.byAppAndType
                .filter(message => message.inboxType === inboxFilter.inboxType);

            filtered.byTypeAndOwner = filtered.byTypeAndOwner
                .filter(message => message.inboxType === inboxFilter.inboxType);

            filtered.byAll = filtered.byAll.filter(message => message.inboxType === inboxFilter.inboxType);
        }

        if(inboxFilter.ownerName) {
            filtered.byAppAndOwner = filtered.byAppAndOwner
                .filter(message => message.ownerName === inboxFilter.ownerName);

            filtered.byTypeAndOwner = filtered.byTypeAndOwner
                .filter(message => message.ownerName === inboxFilter.ownerName);

            filtered.byAll = filtered.byAll.filter(message => message.ownerName === inboxFilter.ownerName);
        }

        return filtered;
    };

    const reduceToCategories = messagesToReduce => {
        return messagesToReduce.reduce((messages, message) => {
            messages.total++;

            const app = messages.apps.find(app => app.title === message.appName);
            if(app) {
                app.count++
            } else {
                messages.apps.push({title: message.appName, count: 1})
            }
            const type = messages.types.find(type => type.title === message.inboxType);
            if(type) {
                type.count++
            } else {
                messages.types.push({title: message.inboxType, count: 1})
            }

            const owner = messages.owners.find(owner => owner.title === message.ownerName);
            if(owner) {
                owner.count++
            } else if(message.ownerName) {
                messages.owners.push({title: message.ownerName, count:1})
            }

            return messages;
        }, {apps: [], types: [], owners: [], total: 0});
    };

    const categorizeMessages = (messages) => {
        return {
            all: reduceToCategories(messages.all),
            filtered: reduceToCategories(messages.byAll),
            byAppAndType: reduceToCategories(messages.byAppAndType),
            byAppAndOwner: reduceToCategories(messages.byAppAndOwner),
            byTypeAndOwner: reduceToCategories(messages.byTypeAndOwner)
        }
    };

    const messagesFiltered = filterMessages(inbox.messages, inboxFilter);

    const onMenuClick = mobile => url => {
        if(mobile) {
            appDispatch({type: MENU_CLOSED})
        }
        navigate(url)
    }

    const messagesByCategory = categorizeMessages(messagesFiltered)
    const handleClear = () => {
        dispatch(unfilterInboxByApp())
        dispatch(unfilterInboxByType())
        dispatch(unfilterInboxByOwner())
    }

    const handleByType = type => {
        dispatch(
            filterInboxByType(inboxFilter.inboxType === type.title ? '' : type.title)
        )
    }

    const handleByApp = app => {
        dispatch(
            filterInboxByApp(inboxFilter.appName === app.title ? '' : app.title)
        )
    }

    const handleByMuseum = museum => {
        dispatch(filterInboxByOwner(inboxFilter.ownerName === museum.title ? '' : museum.title))
    }

    const mainMenu = () => {
        return (
            <List>
                <ListItemButton
                    onClick={handleClear}
                >
                    <ListItemText>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Typography>
                                Innboks
                            </Typography>
                            <Typography>
                                {messagesByCategory.all.total}
                            </Typography>
                        </Stack>
                    </ListItemText>
                </ListItemButton>
            </List>
        )
    }

    const applicationMenu = () => {
        return (
            <List
                subheader={
                    <ListSubheader>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Apps />
                            <Box>
                                Applikasjoner
                            </Box>
                        </Stack>
                    </ListSubheader>
                }
            >
                {messagesByCategory.all.apps.map(app => (
                    <ListItemButton
                        key={app.title}
                        sx={{
                            ml: 2
                        }}
                        dense={true}
                        onClick={() => handleByApp(app)}
                    >
                        <ListItemText>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                <Typography>
                                    {app.title}
                                </Typography>
                                <Typography>
                                    {app.count}
                                </Typography>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        )
    }

    const typeMenu = () => {
        return (
            <List
                subheader={
                    <ListSubheader>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Interests />
                            <Box>
                                Typer
                            </Box>
                        </Stack>
                    </ListSubheader>
                }
            >
                {messagesByCategory.byAppAndOwner.types.map(type => (
                    <ListItemButton
                        key={type.title}
                        sx={{
                            ml: 2
                        }}
                        dense={true}
                        onClick={() => handleByType(type)}
                    >
                        <ListItemText>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                <Typography>
                                    {t(type.title)}
                                </Typography>
                                <Typography>
                                    {type.count}
                                </Typography>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        )
    }

    const museumsMenu = () => {
        return (
            <List
                subheader={
                    <ListSubheader>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Museum />
                            <Box>
                                Museer
                            </Box>
                        </Stack>
                    </ListSubheader>
                }
            >
                {messagesByCategory.byAppAndType.owners.map(owner => (
                    <ListItemButton
                        key={owner.title}
                        sx={{
                            ml: 2
                        }}
                        dense={true}
                        onClick={() => handleByMuseum(owner)}
                    >
                        <ListItemText>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                <Typography>
                                    {owner.title}
                                </Typography>
                                <Typography>
                                    {owner.count}
                                </Typography>
                            </Stack>
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        )
    }

    if(userIsAuthenticated) {
        return (
            <nav className={classes.drawer}>
                <Box
                    sx={{
                        width: {
                            md: menuOpen ? drawerWidth : 0
                        },
                        flexShrink: {
                            md: 0
                        },
                    }}
                >
                    <Hidden mdUp implementation={"js"}>
                        <Drawer
                            open={menuOpen}
                            onClose={() => appDispatch({type: MENU_CLOSED})}
                            PaperProps={{
                                elevation: 4
                            }}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.drawerContainer}>
                                {mainMenu()}
                                <Divider />
                                {applicationMenu()}
                                <Divider />
                                {typeMenu()}
                                <Divider />
                                {museumsMenu()}
                            </div>
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown implementation="js">
                        <Drawer
                            open={menuOpen}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="persistent"
                        >
                            <div className={classes.drawerContainer}>
                                <Toolbar />
                                {mainMenu()}
                                <Divider />
                                {applicationMenu()}
                                <Divider />
                                {typeMenu()}
                                <Divider />
                                {museumsMenu()}
                            </div>
                        </Drawer>
                    </Hidden>
                </Box>
            </nav>
        );
    } else {
        return null;
    }
}