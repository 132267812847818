import React from 'react';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import configureStore from './app/store';
import './app/i18n';
import reportWebVitals from "./reportWebVitals";
import {createRoot} from "react-dom/client";

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <Provider store={configureStore()}>
        <App/>
    </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();