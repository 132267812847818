import React from 'react';
import {StyledEngineProvider} from "@mui/material/styles";
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {deepOrange, lightBlue, lime} from "@mui/material/colors";

export const Theme = ({children}) => {
    const appTheme = createAppTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <Box
                    sx={{
                        zIndex: 1,
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    {children}
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export const themeOptions = {
    palette: {
        primary: deepOrange,
        secondary: lightBlue
    }
}

const createAppTheme = () => createTheme(themeOptions)