export const mapFromRepository = museum => ({
    id: museum.id,
    name: museum.name || '',
    shortName: museum.short_name || '',
    primaryColor: museum.primary_color || "#d94353",
    lightColor: museum.light_color || "#ee7e8f",
    darkColor: museum.dark_color || "#ae3552",
    mediaImage: mapMedia('media', museum),
    logoImage: mapMedia('logo', museum),
    profileImage: mapMedia('profile', museum),
    shareStatistics: museum.share_statistics,
    publishToEuropeana: museum.publish_to_europeana,
    type: museum.type,
    ekulturId: museum.ekultur_id,
    kulturnavId: museum.kulturnav_id
})

const mapMedia = (key, museum) => ({
    imageUrl: museum[key] ? 'https://' + museum[key] : null,
    mediaId: museum[key] ? museum[key].split('/').slice(-1)[0] : ''
})