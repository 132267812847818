import React from 'react';
import {refreshToken} from "@ekultur/authentication";
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext";
import {useLocation} from 'react-router-dom';
import {makeStyles} from "@mui/styles";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex"
    },
}))

export const WithAuthentication = ({children}) => {
    const classes = useStyles();
    const location = useLocation()
    const dispatch = useAuthsDispatch();

    useDeepCompareEffectNoCheck(() => {
        if(!location.pathname.startsWith('/oauth2/callback')) {
            refreshToken({
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: () => dispatch({type: USER_LOGGED_IN})
            })
        }
    }, [])

    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}