import {GET_MESSAGE_FAILED, GET_MESSAGE_RECEIVED, GET_MESSAGE_REQUESTED} from "./messageAction";

const initialState = {
    fetching: false,
    fetchFailed: false,
    item: {}
};

export const message = (state = initialState, action) => {
    switch(action.type) {
        case GET_MESSAGE_REQUESTED:
            return {
                ...state,
                fetching: true,
                fetchFailed: false
            };
        case GET_MESSAGE_RECEIVED:
            return {
                ...state,
                fetching: false,
                item: {
                    ...state.item,
                    [action.uniqueId]: action.message
                }
            };
        case GET_MESSAGE_FAILED:
            return {
                ...state,
                fetching: false,
                fetchFailed: true
            };
        default:
            return state
    }
};
