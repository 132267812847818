import {lazy} from "react";
import {RenderWhenMatch} from "../app/RenderWhenMatch";
import {useAuthenticatedRouteMatch} from "../auths/useAuthenticatedRouteMatch";

const InboxMessage = lazy(() => import('./InboxMessage'))

export const InboxMessageRoute = () => {
    const match = useAuthenticatedRouteMatch("/inbox/:appName/:uniqueId");
    return (
        <RenderWhenMatch match={match}>
            <InboxMessage />
        </RenderWhenMatch>
    )
}