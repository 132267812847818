import React, {Component} from 'react';
import './App.css';
import {AppRoutes} from "./AppRoutes";
import {BrowserRouter as Router} from "react-router-dom";
import {HeaderProvider, WithHeader} from "@ekultur/header-microfrontend";
import {AppProvider} from "./appContext";
import {WhenHeaderIsReady} from "../header/WhenHeaderIsReady";
import {Theme} from "../layout/Theme";
import {Menu} from "../layout/Menu";
import {Box, Toolbar} from "@mui/material";
import {Main} from "./Main";

class App extends Component {
    render() {
        return (
            <Theme>
                <Router>
                    <HeaderProvider>
                        <AppProvider>
                            <WithHeader>
                                <WhenHeaderIsReady>
                                    <Box
                                        sx={{
                                            display: "flex"
                                        }}
                                    >
                                        <Menu/>
                                        <Main>
                                            <Toolbar />
                                            <AppRoutes/>
                                        </Main>
                                    </Box>
                                </WhenHeaderIsReady>
                            </WithHeader>
                        </AppProvider>
                    </HeaderProvider>
                </Router>
            </Theme>
        );
    }
}

export default App;
