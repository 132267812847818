import {useAuthenticatedRouteMatch} from "../auths/useAuthenticatedRouteMatch";

export const MessageRedirecter = () => {
    const match = useAuthenticatedRouteMatch("/message");

    if(match) {

    }
    return null

    // const location = useLocation();
    // const history = useHistory();
    // const {appName, uniqueId} = parse(location.search);
    //
    // history.push(`/inbox/${appName}/${uniqueId}/`);
    // return null
};