import {useAppState} from "./appContext";
import {Container} from "@mui/material";

export const Main = ({children}) => {
    const {menuOpen} = useAppState()

    return (
        <Container
            maxWidth={"false"}
            sx={{
                width: menuOpen ? "calc(100% - 250px)" : "100%"
            }}
        >
            {children}
        </Container>
    )
}