import {GET_MESSAGES_FAILED, GET_MESSAGES_RECEIVED, GET_MESSAGES_REQUESTED} from "./messagesAction";

const initialState = {
    fetching: false,
    fetchFailed: false,
    messages: []
};

export const inbox = (state = initialState, action) => {
    switch(action.type) {
        case GET_MESSAGES_REQUESTED:
            return {
                ...state,
                fetching: true,
                fetchFailed: false
            };
        case GET_MESSAGES_RECEIVED:
            return {
                ...state,
                fetching: false,
                messages: action.messages,
            };
        case GET_MESSAGES_FAILED:
            return {
                ...state,
                fetching: false,
                fetchFailed: true
            };
        default:
            return state
    }
};
