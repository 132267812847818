import React, {Suspense} from 'react';
import {MessageRedirecter} from "../message/MessageRedirecter";
import {WithAuthentication} from "../auths/WithAuthentication";
import {Callback} from "../auths/Callback";
import {WithUserdata} from "../auths/WithUserdata";
import {InboxMessagesRoute} from "../messages/InboxMessagesRoute";
import {InboxMessageRoute} from "../message/InboxMessageRoute";
import {ProgressIndicator} from "./ProgressIndicator";


export const AppRoutes = () => {
    return (
        <WithAuthentication>
            <Callback />
            <WithUserdata>
                <Suspense fallback={<ProgressIndicator/>}>
                    <InboxMessagesRoute />
                    <InboxMessageRoute />
                    <MessageRedirecter />
                </Suspense>
            </WithUserdata>
        </WithAuthentication>
    )
};
