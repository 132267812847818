import React, {useEffect} from 'react';
import {APPS_RECEIVED, useAppDispatch, useAppState} from "../app/appContext";
import {kitFetch} from "@ekultur/fetch";

export const WithApplications = ({children}) => {
    const dispatch = useAppDispatch()
    const {refreshApplications} = useAppState()

    useEffect(() => {
        kitFetch('/app-registry/apps/')
            .then(json => dispatch({
                type: APPS_RECEIVED,
                apps: json.sort((a, b) => a.title.localeCompare(b.title))
            }))
    }, [dispatch, refreshApplications])

    return (
        <>
            {children}
        </>
    )

}