export const LAYOUTMODE_CHANGED = 'LAYOUTMODE_CHANGED';

export const setFinderMode =() => dispatch => {
    dispatch({
        type: LAYOUTMODE_CHANGED,
        layout: 'finder'
    })
};

export const setEditorMode =() => dispatch => {
    dispatch({
        type: LAYOUTMODE_CHANGED,
        layout: 'editor'
    })
};