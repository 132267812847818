import {useAppState} from "../app/appContext";
import {useAuthsState} from "../auths/authsContext";

export const useMuseumsICanSee = () => {
    const {userData} = useAuthsState()
    const {museums} = useAppState()

    if (museums.length) {
        if(userData?.appAccess) {
            const userMuseums = userData.appAccess.museums
                .filter(museum => museum.applications
                    .filter(application => window._env_.REACT_APP_ID === application.id && application.role.name === 'Admin').length)
            const myMuseumIds = userMuseums.map(museum => museum.id)
            return museums.filter(museum => myMuseumIds.includes(museum.id))
                .sort((a, b) => a.name.localeCompare(b.name))
        } else {
            return []
        }
    } else {
        return []
    }
}