import { combineReducers } from 'redux'
import layoutReducer from "../layout/layoutReducer";
import {inbox} from "../messages/messagesReducer";
import {message} from '../message/messageReducer';
import {inboxFilter} from "../messages/filterReducer";

export default () => combineReducers({
    layout: layoutReducer,
    inbox: inbox,
    inboxFilter: inboxFilter,
    message: message
})