import queryString from "query-string";
import {kitFetch} from "@ekultur/fetch";

export const GET_MESSAGE_REQUESTED = 'message/requested';
export const GET_MESSAGE_RECEIVED = 'message/received';
export const GET_MESSAGE_FAILED = 'message/getFailed';
export const POST_MESSAGE = 'message/postRequested';
export const MESSAGE_POSTED = 'message/posted';
export const POST_MESSAGE_FAILED = 'message/postFailed';

export const fetchMessage = (uniqueId, appName) => dispatch => {
    dispatch({
        type: GET_MESSAGE_REQUESTED,
        uniqueId: uniqueId,
        appName: appName
    });

    const params = {
        appName: appName
    };

    kitFetch(`/inbox-api/messages/${uniqueId}/?${queryString.stringify(params)}`)
        .then(json => dispatch(receiveMessage(uniqueId, json)))
        .catch(() => dispatch({type: GET_MESSAGE_FAILED}))

};

const receiveMessage = (uniqueId, message) => ({
    type: GET_MESSAGE_RECEIVED,
    uniqueId: uniqueId,
    message: message
});

export const postMessage = (uniqueId, topic_id, message, appName) => dispatch => {
    dispatch({type: POST_MESSAGE});
    const params = {
        appName: appName
    };

    const options = {
        method: 'POST',
        body: JSON.stringify({
            id: topic_id,
            raw: message
        })
    };

    kitFetch(`/inbox-api/messages/?${queryString.stringify(params)}`, options)
        .then(() => {
            dispatch({type: MESSAGE_POSTED});
            dispatch(fetchMessage(uniqueId, appName))
        })
        .catch(() => dispatch({type: POST_MESSAGE_FAILED}))
};