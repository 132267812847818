import {useAuthsState} from "./authsContext";
import {useMatch} from "react-router-dom";
import {useHasAccess} from "./useHasAccess";

export const useAuthenticatedRouteMatch = path => {
    const {userIsAuthenticated} = useAuthsState();
    const hasAccess = useHasAccess()
    const match = useMatch(path);

    if(userIsAuthenticated && (hasAccess || '/' == path)) {
        return match;
    } else {
        return null
    }
}