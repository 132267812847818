import queryString from "query-string";
import {kitFetch} from "@ekultur/fetch";

export const GET_MESSAGES_REQUESTED = 'messages/requested';
export const GET_MESSAGES_RECEIVED = 'messages/received';
export const GET_MESSAGES_FAILED = 'messages/getFailed';


export const fetchMessages = (ownerId) => dispatch => {
    dispatch({type: GET_MESSAGES_REQUESTED});
    let url = `/inbox-api/messages/`;

    if(ownerId) {
        const params = {
            owner: ownerId,
            start: 0,
            rows: 50
        };
        url = `${url}?${queryString.stringify(params)}`
    }
    kitFetch(url)
        .then(messages => {
            dispatch(receiveMessages(messages))
        })
        .catch(() => dispatch({type: GET_MESSAGES_FAILED}))
};

const receiveMessages = (messages) => ({
    type: GET_MESSAGES_RECEIVED,
    messages,
});