export const INBOX_FILTER_BY_APP_TRIGGERED = 'INBOX_FILTER_BY_APP_TRIGGERED';
export const INBOX_FILTER_BY_TYPE_TRIGGERED = 'INBOX_FILTER_BY_TYPE_TRIGGERED';
export const INBOX_FILTER_BY_OWNER_TRIGGERED = 'INBOX_FILTER_BY_OWNER_TRIGGERED';

export const filterInboxByApp = appName => dispatch => {
    dispatch({
        type: INBOX_FILTER_BY_APP_TRIGGERED,
        appName: appName
    })
};

export const unfilterInboxByApp = () => dispatch => {
    dispatch({
        type: INBOX_FILTER_BY_APP_TRIGGERED,
        appName: ''
    })
};

export const filterInboxByType = inboxType => dispatch => {
    dispatch({
        type: INBOX_FILTER_BY_TYPE_TRIGGERED,
        inboxType: inboxType
    })
};

export const unfilterInboxByType = () => dispatch => {
    dispatch({
        type: INBOX_FILTER_BY_TYPE_TRIGGERED,
        inboxType: ''
    })
};

export const filterInboxByOwner = ownerName => dispatch => {
    dispatch({
        type: INBOX_FILTER_BY_OWNER_TRIGGERED,
        ownerName: ownerName
    })
};

export const unfilterInboxByOwner = () => dispatch => {
    dispatch({
        type: INBOX_FILTER_BY_OWNER_TRIGGERED,
        ownerName: ''
    })
};