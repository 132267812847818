import {lazy} from "react";
import {RenderWhenMatch} from "../app/RenderWhenMatch";
import {useAuthenticatedRouteMatch} from "../auths/useAuthenticatedRouteMatch";

const InboxMessages = lazy(() => import('./InboxMessages'))

export const InboxMessagesRoute = () => {
    const match = useAuthenticatedRouteMatch("/");
    return (
        <RenderWhenMatch match={match}>
            <InboxMessages />
        </RenderWhenMatch>
    )
}