import {
    INBOX_FILTER_BY_APP_TRIGGERED,
    INBOX_FILTER_BY_OWNER_TRIGGERED,
    INBOX_FILTER_BY_TYPE_TRIGGERED
} from "./filterAction";

const initialState = {
    appName: '',
    inboxType: '',
    ownerName: ''
};

export const inboxFilter = (state=initialState, action) => {
    switch(action.type) {
        case INBOX_FILTER_BY_APP_TRIGGERED:
            return {
                ...state,
                appName: action.appName
            };
        case INBOX_FILTER_BY_TYPE_TRIGGERED:
            return {
                ...state,
                inboxType: action.inboxType
            };
        case INBOX_FILTER_BY_OWNER_TRIGGERED:
            return {
                ...state,
                ownerName: action.ownerName
            };
        default:
            return state
    }
};